import PersonImage from "resources/images/person2.png";

const Innovation = () => {
  return (
    <div className="bg-white mx-14 lg:mx-28 my-10 lg:mt-20 lg:my-10 flex justify-center items-center">
      <div className="flex flex-col lg:flex-row justify-center items-center gap-8 lg:gap-32">
        <img
          src={PersonImage}
          alt=""
          className="w-full max-w-[400px] lg:max-w-[900px] lg:w-2/5"
        />
        <div className="flex flex-col justify-center">
          <div className="max-w-96 font-syne font-bold text-4xl mb-3 lg:mb-6">
            Riding the Wave of Innovation
          </div>
          <div className="mb-3 lg:mb-6 max-w-[420px] opacity-70">
            We stay ahead of the curve by specializing in the latest IT
            solutions that are reshaping industries worldwide
          </div>
          <div className="max-w-[420px] opacity-70">
            Leveraging the scalability and flexibility of cloud technologies to
            optimize infrastructure and reduce costs.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Innovation;
