import ClientReviews from "components/ClientReviews";
import Innovation from "components/Innovation";
import Intro from "components/Intro";
import WhyUs from "components/WhyUs";

const Home = () => {
  return (
    <div>
      <Intro />
      <WhyUs />
      <Innovation />
      <ClientReviews />
    </div>
  );
};

export default Home;
