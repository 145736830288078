import Logo from "resources/icons/logo.svg";
import EnvelopIcon from "resources/icons/envelope.svg";
import PhoneIcon from "resources/icons/phone.svg";

const Footer = () => {
  return (
    <div className="w-full lg:px-32 py-4 bg-bprimary text-white">
      {/* middle Section */}
      <div className="w-full py-8 flex flex-col lg:flex-row justify-between items-center gap-8">
        {/* Logo */}
        <div className="flex flex-col items-start gap-2 -ml-8 lg:ml-0">
          <div className="flex items-center gap-2">
            <img src={Logo} alt="logo" className="w-10 h-10 select-none" />
            <div>
              <div className="font-bold text-xl font-syne">Infinity Edge</div>
              <p className="text-gray-400 text-sm font-medium">
                Innovate, Integrate, Elevate
              </p>
            </div>
          </div>
        </div>

        {/* details */}
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Contact */}
          <div className="flex flex-col items-start gap-2">
            <div className="font-semibold text-lg">Contact Us</div>
            <div className="text-sm text-gray-400 flex items-center gap-2">
              <img src={EnvelopIcon} alt="" className="text-white" />
              <a
                href="mailto:infinityedge821@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300"
              >
                infinityedge821@gmail.com
              </a>
            </div>
            <div className="text-sm text-gray-400 flex items-center gap-2">
              <img src={PhoneIcon} alt="" className="text-white" />
              <a
                href="tel:(269) 237-5763"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-gray-300"
              >
                (269) 237-5763
              </a>
            </div>
          </div>

          {/* Address */}
          <div className="flex flex-col items-start gap-2">
            <div className="font-semibold text-lg">Address</div>
            <a
              href="https://www.google.com/maps/search/?api=1&query=156+W+Michigan+Ave+STE+1107+Jackson,+MI+49201"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-gray-400 hover:text-gray-200 max-w-60"
            >
              156 W Michigan Ave STE 1107 Jackson, MI 49201
            </a>
          </div>
        </div>
      </div>

      {/* bottom Section */}
      <div className="w-full border-t border-white/20 mt-4 pt-4 flex flex-col lg:flex-row justify-between items-center font-normal">
        <div className="text-sm text-center lg:text-left mb-2 lg:mb-0">
          © Copyright 2024, All Rights Reserved
        </div>

        <div className="text-sm text-center lg:text-right space-x-4">
          <a href="/privacy-policy" className="cursor-pointer hover:opacity-80">
            Privacy Policy
          </a>
          <a
            href="/terms-and-conditions"
            className="cursor-pointer hover:opacity-80"
          >
            Terms & Conditions
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
