export const Intro = ({ children }) => (
  <div className="w-full h-[250px] lg:h-[300px] flex justify-center items-center bg-bprimary">
    <div className="font-bold font-syne text-white text-5xl mx-8 leading-tight lg:text-6xl text-center">
      {children}
    </div>
  </div>
);

export const Wrapper = ({ children }) => (
  <div className="mx-6 my-10 lg:mx-32 lg:my-28 rounded-lg bg-[#EEEFE7] px-7 py-10 lg:px-16 lg:py-12">
    {children}
  </div>
);

export const Text = ({ children }) => (
  <div className="opacity-70">{children}</div>
);

export const Heading = ({ children }) => (
  <div className="font-bold text-3xl mb-5">{children}</div>
);

export const Section = ({ children }) => (
  <div className="mb-10 leading-relaxed">{children}</div>
);

export const NumberedHeading = ({ children }) => (
  <div className="font-bold text-lg mb-4">{children}</div>
);

export const UL = ({ children }) => (
  <ul className="list-disc ml-5">{children}</ul>
);

export const LI = ({ children }) => <li>{children}</li>;
