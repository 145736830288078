import Logo from "resources/icons/logo.svg";

const Header = () => {
  return (
    <div className="w-full h-20 flex justify-between items-center bg-bsecondary px-4 md:px-28 text-white">
      <a href="/" className="flex items-center gap-2 cursor-pointer">
        <img src={Logo} alt="logo" className="w-11 h-11" />
        <div className="font-bold text-xl font-syne">Infinity Edge</div>
      </a>
      <div className="hidden md:flex gap-4 font-medium">
        <a href="/privacy-policy" className="cursor-pointer hover:opacity-80">
          Privacy policy
        </a>
        <a
          href="/terms-and-conditions"
          className="cursor-pointer hover:opacity-80"
        >
          Terms and conditions
        </a>
      </div>
    </div>
  );
};

export default Header;
