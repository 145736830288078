import {
  Heading,
  LI,
  NumberedHeading,
  Section,
  Text,
  UL,
  Wrapper,
} from "components/PolicyComponents";

const TermsAndConditions = () => {
  return (
    <Wrapper>
      <Heading>Terms And Conditions</Heading>
      <Section>
        <Text>
          We may update the
          <a href="/privacy-policy" className="underline mx-1 font-semibold">
            Privacy Policy
          </a>
          from time to time. We will notify you of any significant changes. By
          using our website and services, you consent to the collection and use
          of your information as described in the Privacy Policy.
        </Text>
      </Section>
      <Section>
        <NumberedHeading>1. Acceptance of Terms</NumberedHeading>
        <Text>
          By accessing and using our website and services, you agree to be bound
          by these Terms and Conditions. If you do not agree to these terms,
          please do not use our website or services.
        </Text>
      </Section>
      <Section>
        <NumberedHeading>2. Use of Our Website and Services</NumberedHeading>
        <Text>
          Permitted Use: You may use our website and services for lawful
          purposes only.
        </Text>
        <Text>Prohibited Use: You may not use our website or services to:</Text>
        <Text>
          <UL>
            <LI>Violate any applicable laws or regulations</LI>
            <LI>Harm, abuse, or harass others</LI>
            <LI>
              Interfere with the security or functionality of our website or
              services
            </LI>
            <LI>Transmit viruses, malware, or other harmful code</LI>
            <LI>
              Collect or store personal information about others without their
              consent
            </LI>
          </UL>
        </Text>
      </Section>
      <Section>
        <NumberedHeading>3. Intellectual Property</NumberedHeading>
        <Text>All content on our website, including</Text>
        <Text>
          text, graphics, logos, images, and software, is the property of{" "}
          <b>Infinity Edge LLC</b> or its licensors and is protected by
          copyright and other intellectual property laws.  
        </Text>
      </Section>
    </Wrapper>
  );
};

export default TermsAndConditions;
