import StarIcon from "resources/icons/star2.svg";

const Star = () => <img src={StarIcon} alt="*" className="w-6" />;

const ReviewCard = ({ reviewText, userName, jobTitle }) => {
  return (
    <div className="w-[280px] lg:w-[360px] rounded-lg py-6 px-5 lg:px-6 bg-[#EEEFE7]">
      <div className="flex space-x-1 mb-8">
        {Array(5)
          .fill()
          .map((_, index) => (
            <Star key={index} />
          ))}
      </div>

      <div className="mb-2">{reviewText}</div>

      <div className="font-semibold text-sm">
        {userName} - <span className="text-[#6B6F7B]">{jobTitle}</span>
      </div>
    </div>
  );
};

export default ReviewCard;
