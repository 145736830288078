import ReviewCard from "./ReviewCard";
import SnakeIcon2 from "resources/icons/snake2.svg";
import SnakeIcon3 from "resources/icons/snake3.svg";

const ClientReviews = () => {
  return (
    <div className="bg-white px-4 lg:px-32 lg:py-28 relative max-w-[1600px] mx-auto">
      <img
        src={SnakeIcon2}
        alt=""
        className="absolute w-24 left-10 top-8 hidden lg:block"
      />

      <div className="bg-white flex justify-center">
        <div className="px-4 py-6 flex flex-col items-start">
          <div className="font-bold text-4xl font-syne mb-8 lg:mb-16 text-center lg:text-start w-full">
            What Our Clients Say
          </div>
          <div className="flex flex-wrap justify-center gap-4 lg:gap-6">
            <ReviewCard
              reviewText="“Working with InfinityEdge was a game-changer for our business. They delivered a top-notch solution that exceeded our expectations.”"
              userName="Michael Smith"
              jobTitle="Writers Hub"
            />

            <ReviewCard
              reviewText="“They were able to quickly understand our needs and provide a customized solution that perfectly aligned with our business goals.”"
              userName="David Wilson"
              jobTitle="Manager Tech Waves"
            />

            <ReviewCard
              reviewText="“I highly recommend InfinityEdge to anyone looking for a reliable and professional software development partner.”"
              userName="Emma Davis"
              jobTitle="CEO Managerin"
            />
          </div>
        </div>
      </div>

      <img
        src={SnakeIcon3}
        alt=""
        className="absolute w-40 right-8 bottom-2 hidden lg:block"
      />
    </div>
  );
};

export default ClientReviews;
