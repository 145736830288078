import PrivacyIntro from "components/PrivacyIntro";
import PrivacyPolicy from "components/PrivacyPolicy";

const Privacy = () => {
  return (
    <div>
      <PrivacyIntro />
      <PrivacyPolicy />
    </div>
  );
};

export default Privacy;
