import PersonImage from "resources/images/person1.png";
import StarIcon from "resources/icons/star.svg";
import ShineIcon from "resources/icons/sideshine.svg";

const Star = () => <img src={StarIcon} alt="*" className="w-6" />;

const Intro = () => {
  return (
    <div>
      <div className="w-full lg:h-[580px] flex justify-center items-center lg:items-end bg-bprimary px-6 pt-10 lg:py-0 lg:px-[10%]">
        <div className="flex justify-between w-full lg:gap-10">
          <div className="text-white mb-10">
            <div className="font-syne font-bold text-4xl lg:text-6xl lg:max-w-[650px] mb-7">
              Cutting edge software solutions
            </div>
            <div className="lg:text-lg lg:max-w-[550px] mb-6">
              Our team of skilled developers is dedicated to delivering
              exceptional software products that empower your business.
            </div>
            <div className="flex gap-3">
              <div className="flex mb-2">
                <Star />
                <Star />
                <Star />
                <Star />
                <Star />
              </div>
              <div className="text-sm lg:text-base">
                Loved by teams worldwide
              </div>
            </div>
            <div className="text-sm opacity-50 mt-1">
              97% of them think InfinityEdge is great!
            </div>
          </div>
          <div className="w-0 relative rounded-lg lg:w-[450px]">
            <img src={PersonImage} alt="person" className="w-full h-full" />
            <img
              src={ShineIcon}
              alt=""
              className="w-14 absolute -top-14 -left-14"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
