import CardIcon1 from "resources/icons/icon1.svg";
import CardIcon2 from "resources/icons/icon2.svg";
import CardIcon3 from "resources/icons/icon3.svg";
import SnakeIcon from "resources/icons/snake.svg";

const Card = ({ img, heading, text }) => {
  return (
    <div className="w-[280px] lg:w-[360px] h-[240px] lg:h-[270px] bg-white rounded flex flex-col justify-center items-center">
      <img src={img} alt="" className="h-[70px] mb-6" />
      <div className="font-bold text-lg mb-3">{heading}</div>
      <div className="text-sm font-[#12141D] opacity-70 max-w-[240px] text-center">
        {text}
      </div>
    </div>
  );
};

const WhyUs = () => {
  return (
    <div className="bg-[#EEEFE7] flex flex-col items-center py-16 lg:py-24">
      <div className="font-syne lg:my-10 font-bold text-3xl lg:text-4xl relative">
        Why Choose Us?
        <img
          src={SnakeIcon}
          alt=""
          className="absolute w-28 -left-60 -top-10 hidden lg:block"
        />
      </div>
      <div className="mt-10 flex flex-wrap justify-center gap-5 px-4 lg:px-20">
        <Card
          img={CardIcon1}
          heading={"Custom Solutions"}
          text={
            "We tailor our solutions to your specific needs, ensuring a perfect fit for your business."
          }
        />
        <Card
          img={CardIcon2}
          heading={"Quality Assurance"}
          text={
            "Rigorous testing and quality control measures guarantee the highest standards."
          }
        />
        <Card
          img={CardIcon3}
          heading={"Cost-Effective Solutions"}
          text={
            "We offer competitive pricing without sacrificing quality. We work closely with you throughout the process."
          }
        />
      </div>
    </div>
  );
};

export default WhyUs;
