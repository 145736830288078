import {
  Heading,
  LI,
  NumberedHeading,
  Section,
  Text,
  UL,
  Wrapper,
} from "components/PolicyComponents";

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <Heading>Privacy Policy</Heading>
      <Section>
        <Text>
          <b>Infinity Edge LLC</b> respects your privacy and is committed to
          protecting your personal information. This Privacy Policy outlines how
          we collect, use, disclose, and safeguard your information when you
          interact with our website and services.  
        </Text>
      </Section>
      <Section>
        <NumberedHeading>1. Information We Collect</NumberedHeading>
        <Text>
          We may collect the following types of personal information:
          <UL>
            <LI>
              Personal Information: Name, email address, phone number, and other
              contact details.
            </LI>
            <LI>
              Usage Data: Information about how you interact with our website,
              such as your IP address, browser type, and browsing history.
            </LI>
            <LI>
              Payment Information: Credit card or other payment information when
              making purchases.
            </LI>
          </UL>
        </Text>
      </Section>
      <Section>
        <NumberedHeading>2. How We Use Your Information</NumberedHeading>
        <Text>
          We use your personal information for the following purposes:
        </Text>
        <Text>
          <UL>
            <LI>
              Providing Services: Delivering the products and services you
              request.
            </LI>
            <LI>
              Improving Our Services: Analyzing usage data to enhance our
              website and services.
            </LI>
            <LI>
              Communicating with You: Sending important notices, updates, and
              marketing communications.
            </LI>
            <LI>Processing Payments: Processing your payments securely.</LI>
            <LI>
              Complying with Legal Obligations: Adhering to legal and regulatory
              requirements.
            </LI>
          </UL>
        </Text>
        <Text>We may share your personal information with:</Text>
        <Text>
          <UL>
            <LI>
              Service Providers: Trusted third-party service providers who
              assist us in delivering our services.
            </LI>
            <LI>
              Legal Authorities: If required by law or to protect our rights.
            </LI>
          </UL>
        </Text>
      </Section>
      <Section>
        <NumberedHeading>3. Changes to This Privacy Policy</NumberedHeading>
        <Text>
          We may update this Privacy Policy from time to time. We will notify
          you of any significant changes.
        </Text>
        <Text>
          By using our website and services, you consent to the collection and
          use of your information as described in this Privacy Policy.
        </Text>
      </Section>
    </Wrapper>
  );
};

export default PrivacyPolicy;
