import TermsAndConditions from "components/TermsAndConditions";

const { default: TermsIntro } = require("components/TermsIntro");

const Terms = () => {
  return (
    <div>
      <TermsIntro />
      <TermsAndConditions />
    </div>
  );
};

export default Terms;
